.App {
  text-align: center;
}
body {
  background-color: #282c34;
  font-family: "Droid Sans";
}

a {
  color: #FFFFFF;
}
